import { render, staticRenderFns } from "./MenuAdmin.vue?vue&type=template&id=204e0539&scoped=true&"
import script from "./MenuAdmin.vue?vue&type=script&lang=js&"
export * from "./MenuAdmin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204e0539",
  null
  
)

export default component.exports