<template>
  <ul class="menu-nav">
    <router-link to="/dashboard" v-slot="/* eslint-disable vue/no-unused-vars */ { href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <router-link :to="{ name: 'dashboard' }" class="menu-link" @click="navigate">
          <span class="menu-text">
            {{ $t("GENERAL.DASHBOARD") | uppercase }}
          </span>
        </router-link>
      </li>
    </router-link>

    <router-link :to="{ name: 'redeem' }" v-slot="/* eslint-disable vue/no-unused-vars */ { href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <router-link :to="{ name: 'redeem' }" class="menu-link" @click="navigate">
          <span class="menu-text">
            {{ $t("GENERAL.REDEEM_TOKEN") | uppercase }}
          </span>
        </router-link>
      </li>
    </router-link>
    <menu-admin v-if="currentUser.white_label_enabled"></menu-admin>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import MenuAdmin from "@/view/layout/menu/MenuAdmin";
import uppercase from "@/core/mixins/uppercase.filter";
export default {
  name: "KTMenu",
  components: { MenuAdmin },
  mixins: [uppercase],
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
  },
};
</script>
