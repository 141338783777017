<template>
  <div id="kt_footer" class="footer bg-white py-4 d-flex flex-lg-column">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{
        'container-fluid': widthFluid,
        container: !widthFluid,
      }"
    >
      <!--begin::Copyright-->
      <div class="text-white order-2 order-md-1">
        <img alt="Logo" src="media/logos/life-academy/200.png" class="logo-sticky max-h-35px" />
        <span class="text-muted font-weight-bold mr-2 ml-4">1989 - {{ currentYear }}© {{ layoutConfig("appName") }}</span>

        <span class="text-white">®</span>
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2">
        <router-link :to="{ name: 'general-privacy' }" class="nav-link px-3 opacity-70 pl-0 text-hover-primary">
          {{ $t("GENERAL.PRIVACY") }}
        </router-link>
        <router-link :to="{ name: 'general-contact' }" class="nav-link pl-3 pr-0 opacity-70 pl-0 text-hover-primary">
          {{ $t("GENERAL.CONTACT") }}
        </router-link>
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
    currentYear() {
      let d = new Date();

      return d.getFullYear();
    },
  },
};
</script>
