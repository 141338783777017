var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.$t("GENERAL.ADMIN")))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-active': _vm.hasActiveChildren('tokens-manage'),
        }},[_c('router-link',{staticClass:"menu-link",attrs:{"to":{ name: 'tokens-manage' }}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.$t("GENERAL.MANAGE_TOKENS")))+" ")])])],1),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-active': _vm.hasActiveChildren('tokens-tag'),
        }},[_c('router-link',{staticClass:"menu-link",attrs:{"to":{ name: 'tokens-tag' }}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.$t("GENERAL.MY_GROUPS")))+" ")])])],1),(false)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-active': _vm.hasActiveChildren('view-product'),
        },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-delivery-package"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.$t("GENERAL.TOKENS")))+" ")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/tokens/manage"},scopedSlots:_vm._u([{key:"default",fn:function(/* eslint-disable vue/no-unused-vars */ { href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('router-link',{staticClass:"menu-link",attrs:{"to":{ name: 'tokens-manage' }},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.$t("GENERAL.MANAGE_TOKENS")))+" ")])])],1)]}}],null,false,3320744401)})],1)])]):_vm._e(),(false)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-active': _vm.hasActiveChildren('view-product'),
        },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-delivery-package"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.$t("GENERAL.GROUPS")))+" ")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/groups/my-groups"},scopedSlots:_vm._u([{key:"default",fn:function(/* eslint-disable vue/no-unused-vars */ { href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('router-link',{staticClass:"menu-link",attrs:{"to":{ name: 'tokens-tag' }},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.$t("GENERAL.MY_GROUPS")))+" ")])])],1)]}}],null,false,2390346771)})],1)])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }