<template>
  <li aria-haspopup="true" data-menu-toggle="click" class="menu-item menu-item-submenu menu-item-open-dropdown">
    <a href="#" class="menu-link menu-toggle">
      <span class="menu-text"> {{ $t("GENERAL.ADMIN") | uppercase }} </span>
    </a>
    <div class="menu-submenu menu-submenu-classic">
      <ul class="menu-subnav">
        <li
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-active': hasActiveChildren('tokens-manage'),
          }"
        >
          <router-link :to="{ name: 'tokens-manage' }" class="menu-link">
            <span class="menu-text">
              {{ $t("GENERAL.MANAGE_TOKENS") | uppercase }}
            </span>
          </router-link>
        </li>
        <li
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-active': hasActiveChildren('tokens-tag'),
          }"
        >
          <router-link :to="{ name: 'tokens-tag' }" class="menu-link">
            <span class="menu-text">
              {{ $t("GENERAL.MY_GROUPS") | uppercase }}
            </span>
          </router-link>
        </li>
        <li
          v-if="false"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-active': hasActiveChildren('view-product'),
          }"
        >
          <a href="#" class="menu-link menu-toggle">
            <i class="menu-icon flaticon2-delivery-package"></i>
            <span class="menu-text">
              {{ $t("GENERAL.TOKENS") | uppercase }}
            </span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <router-link to="/tokens/manage" v-slot="/* eslint-disable vue/no-unused-vars */ { href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                  <router-link :to="{ name: 'tokens-manage' }" class="menu-link" @click="navigate">
                    <span class="menu-text">
                      {{ $t("GENERAL.MANAGE_TOKENS") | uppercase }}
                    </span>
                  </router-link>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
        <li
          v-if="false"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-active': hasActiveChildren('view-product'),
          }"
        >
          <a href="#" class="menu-link menu-toggle">
            <i class="menu-icon flaticon2-delivery-package"></i>
            <span class="menu-text">
              {{ $t("GENERAL.GROUPS") | uppercase }}
            </span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <router-link to="/groups/my-groups" v-slot="/* eslint-disable vue/no-unused-vars */ { href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                  <router-link :to="{ name: 'tokens-tag' }" class="menu-link" @click="navigate">
                    <span class="menu-text">
                      {{ $t("GENERAL.MY_GROUPS") | uppercase }}
                    </span>
                  </router-link>
                </li>
              </router-link>
              <!--<router-link to="/groups/manage" v-slot="/* eslint-disable vue/no-unused-vars */ { href, navigate, isActive, isExactActive }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                  <router-link :to="{ name: 'groups-manage' }" class="menu-link" @click="navigate">
                    <span class="menu-text">
                      {{ $t("GENERAL.MANAGE_GROUPS") | uppercase }}
                    </span>
                  </router-link>
                </li>
              </router-link>-->
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "menu-admin",
  props: {},
  mixins: [uppercase],
  computed: {
    ...mapGetters({ programs: "bundle/programs", maps: "bundle/maps", getTypeByCodename: "bundle/getTypeByCodename", currentUser: "auth/currentUser" }),
  },
  data() {
    return {};
  },
  // watch: {
  //   "$route.params.codename"(to, from) {
  //   },
  // },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
